import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Box } from "theme-ui"
import loadable from "@loadable/component"

import Header from "../../components/header"
const Footer = loadable(() => import("../../components/footer"))

type LayoutProps = {
  children: any
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          # author
        }
      }
    }
  `)

  return (
    <>
      <Helmet
        titleTemplate={`%s | ${site.siteMetadata.title}`}
      >
        <html lang="en" />

        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />

        <meta property="og:title" content={site.siteMetadata.title} />
        <meta property="og:description" content={site.siteMetadata.description} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:creator" content={site.siteMetadata.author} /> */}
        <meta name="twitter:title" content={site.siteMetadata.title} />
        <meta name="twitter:description" content={site.siteMetadata.description} />
      </Helmet>

      <Header siteTitle={site.siteMetadata.title} />
      <Box as="main">{children}</Box>
      <Footer siteTitle={site.siteMetadata.title} />
    </>
  )
}

export default Layout
