import React, { useEffect, useState, FC } from "react"
import { Flex, Box } from "theme-ui"
import { alpha } from "@theme-ui/color"

import Logo from "../logo"
import Navigation from "./navigation"

type HeaderProps = {
  siteTitle?: string
}

const Header: FC<HeaderProps> = ({ siteTitle = "" }) => {
  const [compactHeader, setCompactHeader] = useState(false)

  useEffect(() => {
    function handleScroll() {
      setCompactHeader(window.scrollY > 0 ? true : false)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const compactSpeed = "0.25s"

  return (
    <Box
      as="header"
      sx={{
        position: "fixed",
        bg: compactHeader ? theme => alpha("black", 0.7) : null,
        backdropFilter: compactHeader ? "blur(5px)" : null,
        left: 0,
        right: 0,
        zIndex: "header",
        transition: `background-color ${compactSpeed}`,
      }}
    >
      <Flex
        p={compactHeader ? 3 : 4}
        mx={"auto"}
        sx={{
          alignItems: "center",
          maxWidth: "container",
          justifyContent: "space-between",
          transition: `padding ${compactSpeed}`,
        }}
      >
        <Box
          sx={{
            display: "inherit",
            opacity: compactHeader ? 1 : 0,
            transition: `
              opacity ${compactSpeed}`
          }}
        >
          <Logo alt={siteTitle} size={49} />
        </Box>

        <Navigation />
      </Flex>
    </Box>
  )
}

export default React.memo(Header)
