/** @jsx jsx */
import React, { useState, FC } from "react"
import { Drawer } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/pro-duotone-svg-icons"
import { NavLink, Button, Box, Link, jsx } from "theme-ui"

// import Selector from "./selector"
import Logo from "../logo"

enum NavState {
  OPEN,
  CLOSED,
}

const Navigation: FC = () => {
  const [nav, setNav] = useState(NavState.CLOSED)

  const links = [
    "About",
    "Services",
    "Work",
    "Lab",
  ].map(link => <NavLink key={link.toLowerCase()} href={`/#${link.toLowerCase()}`} title={link}>{link}</NavLink>)

  return (
    <>
      <Box
        sx={{
          display: ["none", "initial"]
        }}
      >
        {links}

        {/* TODO: Complete night mode */}
        {/* <Selector /> */}

        <Button
          ml={3}
          as={Link}
          href="#footer"
        >
          Contact Me
        </Button>
      </Box>
      <Button
        name="Menu"
        aria-label="Menu"
        variant={"transparent"}
        onClick={() => setNav(NavState.OPEN)}
        sx={{
          display: ["initial", "none"]
        }}
      >
        <FontAwesomeIcon sx={{color: "white"}} icon={faBars} size="2x" />
      </Button>
      <Drawer
        anchor="right"
        open={nav === NavState.OPEN}
        onClose={() => setNav(NavState.CLOSED)}
        sx={{
          ".MuiDrawer-paper": {
            backgroundColor: (theme) => theme.colors.modes.dark.background,
            color: (theme) => theme.colors.modes.dark.text,
          }
        }}
      >
        <Box
          m={3}
        >
          <Logo size={125} />
        </Box>

        {links}
      </Drawer>
    </>
  )
}

export default React.memo(Navigation)
