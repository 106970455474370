import React, { FC } from "react"
import { Image, ImageProps } from "theme-ui"

import logoBordered from "../../content/images/logo-ab.svg"
import logoBorderless from "../../content/images/logo-ab-borderless.svg"

type LogoProps = {
  alt?: string
  borderless?: boolean
  size?: number
} & ImageProps

const Logo: FC<LogoProps> = ({
  alt = "",
  borderless = false,
  size = 45,
}) => (
  <Image
    src={borderless ? logoBorderless : logoBordered}
    alt={alt}
    title={alt}
    height={size}
    width={size}
  />
)

export default React.memo(Logo)
